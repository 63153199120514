import React, { ReactNode } from "react";
import { Text } from "@epignosis_llc/gnosis";
import { t } from "@utils/i18n";
import permissions from "@utils/permissions";
import { URLS } from "@constants/urls";
import { getURL } from "@api/app";

const { canAccessSubscription } = permissions.accountPermissions;

const getReverseTrialText = (): string => {
  if (!canAccessSubscription()) {
    return t("reverseTrial.limitationTooltip");
  }

  const baseURL = getURL();
  const SUBSCRIPTION_URL = `https://${baseURL}${URLS.subscription.subscription}`;
  return t("reverseTrial.limitationTooltipWithUpgrade", { url: SUBSCRIPTION_URL });
};

export const getReverseTrialTooltip = (): ReactNode => {
  return (
    <Text
      fontSize="xs"
      dangerouslySetInnerHTML={{
        __html: getReverseTrialText(),
      }}
    ></Text>
  );
};
